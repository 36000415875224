import http from "@/api/http.js";

function listAll() {
  return http({
    url: "/SysApp/ListAll",
    method: "get",
  });
}

function create(payload) {
  return http({
    url: "/SysApp/Create",
    method: "post",
    data: payload,
  });
}

function updateStatus(id, status) {
  return http({
    url: "/SysApp/Update",
    method: "post",
    data: {
      id: id,
      status: status,
    },
  });
}

export default {
  listAll,
  create,
  updateStatus,
};
